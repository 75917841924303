import styles from "./FooterLayout.module.scss";
import logo from "../../assets/icons/logo.svg";
import footer_logo_fasie from "../../assets/images/footer_logo_fasie.png";
import {Typography} from "antd";
import React from "react";


interface Props {
  onClick: () => void;
}

export const FooterLayout: React.FC<Props> = ({onClick}) => {
  return (
    <div className={styles.container} >
      <div className={styles.footer}>
        <div className={styles.left}>
          <img src={logo} alt={"логотип"} className={styles.logo} />
          <div className={styles.links}>
            <a className={styles.link}>
              Название компании: ООО “Примерь это”
            </a>
            <a className={styles.link}>
              ОРГН: 1231600052793
            </a>
            <a className={styles.link}>
              ИНН: 168301001
            </a>
            <a className={styles.link}>
              Адрес: 420500, Республика Татарстан, м.р-н Вехнеуслонский, г.Иннополис, ул.Университетская, д.5, помещ. 115, раб.место 23/3
            </a>
            <a className={styles.link}>
              Контакты: Trythisfashion@yandex.com
            </a>
            <a href={"/privacy/"} className={styles.link}>
              Политика в отношении обработки персональных данных
            </a>
            <a href={"/agreement/"} className={styles.link}>
              Пользовательское соглашение
            </a>
            <a className={styles.link}>
              Работа выполнена при поддержке гранта Фонда содействия инновациям, предоставленного в рамках программы «Студенческий стартап» федерального проекта «Платформа университетского технологического предпринимательства».
              <img src={footer_logo_fasie} alt={"footer_logo_fasie"} className={styles.footer_logo_fasie} />
            </a>
          </div>
        </div>
        <div className={styles.right}>
          <Typography className={styles.rightHeader}>
            Гармоничный образ
          </Typography>
          <Typography className={styles.link}>
            Подберите одежду прямо сейчас
          </Typography>
          <button className={styles.btn} onClick={onClick}>
            Попробовать
          </button>
        </div>
      </div>
    </div>
  );
}
